// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
//@include mat.all-legacy-component-typographies();
//@include mat.legacy-core();
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$app-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$app-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $app-primary,
            accent: $app-accent,
            warn: $app-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-legacy-component-themes($app-theme);
@include mat.all-component-themes($app-theme);
// TODO: slim down the import to only needed components (datepicker for example)

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    /** font **/
    --ion-font-family: 'Quicksand', sans-serif;

    /** primary **/
    --ion-color-primary: #2092c9;
    --ion-color-primary-rgb: 32, 146, 201;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #1974a0;
    --ion-color-primary-tint: #4ca7d3;

    /** secondary **/
    --ion-color-secondary: #64c7d0;
    --ion-color-secondary-rgb: 100, 199, 208;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #509fa6;
    --ion-color-secondary-tint: #83d2d9;

    /** tertiary **/
    --ion-color-tertiary: #f0f2f4;
    --ion-color-tertiary-rgb: 240, 242, 244;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #ccd2d9;
    --ion-color-tertiary-tint: #e5ecf3;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #ced6e1;
    --ion-color-medium-rgb: 206, 214, 225;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #b9c0ca;
    --ion-color-medium-tint: #d2dae4;

    /** light **/
    --ion-color-light: #f6f7f8;
    --ion-color-light-rgb: 246, 247, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d3d8dc;
    --ion-color-light-tint: #edf1f6;

    /** border **/
    --ion-border-color: rgb(0, 0, 0, 0.13);
    --ion-border-color-dark: rgb(0, 0, 0, 0.25);

    /** font **/
    --ion-text-color: #000;
    --ion-text-color-rgb: rgb(0, 0, 0);

    /** background **/
    --ion-background-color: #e8eaed;
    --ion-background-color-rgb: 232, 234, 237;
    --ion-item-background: #f0f2f4;
    --ion-toolbar-background: #fff;
    --ion-tab-bar-background: #fff;
    --ion-card-background: #f0f2f4;

    /*ion-card ion-item {
		--ion-item-background: #fff;
	}*/

    /*ion-item.item-input,
	ion-item.item-datetime,
	ion-item.item-checkbox,
	ion-item.item-select,
	ion-item.bg-white {
		--ion-item-background: #fff;
	}*/

    /** height of headers used above content where we need to calc our height minus it **/
    --header-height: 56px;
    /** additonal difference to substract from content height e.g. by using padding to move content further down to not overlap with fabs **/
    --content-inset: 0px;
    &.ios {
        --header-height: 46px;
    }
}

/*
@media (prefers-color-scheme: dark) {

  .ios body, .md body {
      // border
      --ion-border-color: rgb(255, 255, 255, 0.6);
      --ion-border-color-dark: rgb(255, 255, 255, 0.6);

      // font
      --ion-text-color: #fff;
      --ion-text-color-rgb: rgb(255, 255, 255);

      // background
      --ion-background-color: #313539;
      --ion-background-color-rgb: 49, 53, 57;
      --ion-item-background: #313539;
      --ion-toolbar-background: #222527;
      --ion-tab-bar-background: #222527;
      --ion-card-background: #222527;
      ion-card ion-item {
          --ion-item-background: #222527;
      }
      ion-item.item-input {
          --ion-item-background: #222527;
      }

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

  }
}
*/

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// Plus imports for other components in your app.
