/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this attachment can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Core CSS with theme for primeng components */
//@import 'primeng/themes/lara-light-blue/theme.css';
//@import 'primeng/resources/primeng.min.css';

/* configure layer order for compatibility */
@layer normalize, primeng, normalize-reset, dokupit;
@import '@ionic/angular/css/normalize.css' layer(normalize-reset);

/* Basic CSS for apps built with Ionic */
//@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import 'theme/fonts.scss';
@import 'theme/utility.scss';

/**
 * GENERAL STYLING
 * ----------------------------------------------------------------------------
 */

:root {
    // primeng overrides font settings. We need to be more specific by setting these here
    --ion-font-family: 'Quicksand', sans-serif;
    --font-family: var(--ion-font-family), 'Quicksand', sans-serif;
    font-family: var(--ion-font-family), 'Quicksand', sans-serif;
}

html {
    font-size: 15px;
}
body {
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    line-height: 1.5;
}
h1,
.card-content-md h1 {
    font-size: 1.5rem;
}
h2,
.card-content-md h2 {
    font-size: 1.25rem;
}
h3,
.card-content-md h3 {
    font-size: 1.125rem;
}
h4,
.card-content-md h4 {
    font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0;
}

ion-title {
    font-size: 1.125rem;
}

ion-card {
    ion-card-header {
        //font-size: 16px;
    }
    ion-card-title {
        font-size: 1.125rem;
        @media (max-width: 1020px) {
            font-size: 1rem;
        }
        font-weight: 500;
        h1,
        h2,
        h3,
        h4,
        h5 {
            font-weight: 500;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

ion-backdrop {
    opacity: 0.3;
}

ion-item.drawer-item {
    --border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
    border-color: var(--border-color);
    --ion-color-shade: var(--border-color);

    &::part(native) {
        --border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
        border-color: var(--border-color) !important;
        --ion-color-shade: var(--border-color);
    }
}

/**
 * HOTFIX
 */
// ion-select win11 and 10 enterprise
.select-alert {
    --width: 100%;
    z-index: 41000 !important; // our dp-dialogs show at 40000
}

/**
 * GENERAL PAGE STRUCTURE
 * ----------------------------------------------------------------------------
 */
.ion-page {
    overflow: auto;
    contain: initial; // overriden for fixed positioning
}
ion-router-outlet {
    overflow: auto;
}
ion-grid {
    --ion-grid-padding: 0;
}

.component-wrap {
    height: 100%;
    width: 100%;
}

.page-wrap {
    display: flex;
    flex-flow: column;
    flex-grow: 1;

    height: calc(100% - var(--header-height) - var(--content-inset, 0px));

    .page-content {
        flex-grow: 1;
        position: relative;
    }
}

// for all page-wrap siblings next to a header
ion-header + .page-wrap {
    padding-top: 16px; // prevent overlapping fabs
    padding-bottom: var(--ion-safe-area-bottom); // cause bottom swipe area etc
}

/**
 * HEADER ACTION BAR
 * ----------------------------------------------------------------------------
 */
.dp-action-bar {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    @media (max-width: 576px) {
        padding: 0.2rem 1rem;
    }
    @media (min-width: 1200px) {
        padding-inline-start: var(--ion-grid-padding-xl, var(--ion-grid-padding, 5px));
        padding-inline-end: var(--ion-grid-padding-xl, var(--ion-grid-padding, 5px));
    }
}

/**
 * BUTTONS
 * ----------------------------------------------------------------------------
 */
ion-button,
ion-buttons > ion-button {
    text-transform: none;
    white-space: normal;
    font-weight: 500;
    letter-spacing: 0;
    height: auto;
    font-size: 1rem;
    --padding-top: 0.6rem;
    --padding-bottom: 0.6rem;
    --padding-start: 1.1rem;
    --padding-end: 1.1rem;
    &.button-small {
        font-size: 0.85rem;
        --padding-top: 0.4rem;
        --padding-bottom: 0.4rem;
        --padding-start: 1.1rem;
        --padding-end: 1.1rem;
    }
    &.button-large {
        font-size: 1.1rem;
        --padding-top: 0.7rem;
        --padding-bottom: 0.7rem;
        --padding-start: 1.6rem;
        --padding-end: 1.6rem;
    }
    &.round {
        --padding-top: 0;
        --padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;
        height: 2.3rem;
        width: 2.3rem;
        --border-radius: 100% !important;
        border-radius: 100% !important;
        &:hover {
            --border-radius: 100% !important;
            border-radius: 100% !important;
        }
        &.button-small {
            font-size: 0.9rem;
            height: 2rem;
            width: 2rem;
        }
        &.button-large {
            font-size: 1.2rem;
            height: 2.8rem;
            width: 2.8rem;
        }
        &.button-outline,
        &.button-clear {
            transition: background 0.2s ease-out;
            &:hover {
                transition: background 0.2s ease-out;
                border-radius: var(--border-radius);
                background: var(--ion-color-primary);
                color: #fff;
                * {
                    color: #fff;
                }
            }
            &[color='warning'] {
                &:hover {
                    background: var(--ion-color-warning);
                    color: #000;
                    * {
                        color: #000;
                    }
                }
            }
            &[color='danger'] {
                &:hover {
                    background: var(--ion-color-danger);
                    color: #fff;
                    * {
                        color: #fff;
                    }
                }
            }
            &[color='success'] {
                &:hover {
                    background: var(--ion-color-success);
                    color: #fff;
                    * {
                        color: #fff;
                    }
                }
            }
        }
    }
    &.dp-is-loading {
        position: relative;
        &::part(native) {
            cursor: wait;
        }
        fa-icon,
        &:hover fa-icon,
        &:hover fa-icon * {
            color: transparent !important;
        }
        &::before {
            position: absolute;
            left: 11px;
            top: 50%;
            z-index: 9;
            pointer-events: none;
            text-align: center;
            margin-top: -11px;
            font-family: 'Font Awesome 5 Pro';
            font-weight: 400;
            content: '\f3f4';
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -webkit-animation: rotating 0.75s linear infinite;
            -moz-animation: rotating 0.75s linear infinite;
            -o-animation: rotating 0.75s linear infinite;
            animation: rotating 0.75s linear infinite;
        }
    }
    &.dp-is-loading {
        &.ion-color-success,
        &.ion-color-primary,
        &.ion-color-danger,
        &.ion-color-warning {
            &::before {
                color: #fff;
            }
        }
    }
    fa-icon[slot='start'] {
        margin-right: 0.6em;
    }
    fa-icon[slot='end'] {
        margin-left: 0.6em;
    }
    &[size='large'] {
        height: 36px;
    }
    &[fill='solid'] {
        --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
    &[fill='solid']:not(.button-round) {
        --border-radius: 4px;
    }
}

/**
 * ACTION BUTTONS
 * ----------------------------------------------------------------------------
 */
.dp-action-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 425px) {
        justify-content: normal;
    }
    ion-button {
        fa-icon[slot='start'] {
            margin-right: 0.6em;
        }
        fa-icon[slot='end'] {
            margin-left: 0.6em;
        }
    }
    ion-button.icon-only {
        font-size: 1.2rem;
        --padding-top: 0.5rem;
        --padding-bottom: 0.5rem;
        --padding-start: 0.5rem;
        --padding-end: 0.5rem;
    }
}

/**
 * FAB (FLOATING ACTION BUTTONS)
 * ----------------------------------------------------------------------------
 */
ion-fab {
    // display: flex;

    // &[slot='fixed'] {
    //     position: fixed; // fix for default ionic style
    // }
    &.fab-edge {
        top: 28px;
        &.ios {
            top: 16px;
        }
    }
    &.fab-vertical-bottom.fab-edge {
        top: unset;
        bottom: 8px; /* fallback */

        @supports (bottom: max(8px, 8px)) {
            /* detect if max function is supported and then use either safe area or the default 8px */
            bottom: max(var(--ion-safe-area-bottom, 0), 8px);
        }
    }
    ion-fab-button {
        margin-left: 0.5rem;
        fa-icon {
            font-size: 1.35rem;
        }
        &[size='small'],
        &.fab-button-small {
            margin-right: 0;
            fa-icon {
                font-size: 1rem;
            }
        }
    }
}

/* Our new "fab" buttons using grid system */
.dp-g-fab {
    &.fixed {
        // manually setting position
        position: fixed;
    }

    &.dp-edge {
        transform: translateY(-50%);
        z-index: 99999;
    }

    &.h-start {
        left: var(--ion-safe-area-left, 0);
    }
    &.h-end {
        right: var(--ion-safe-area-right, 0);
    }
    &.stretch {
        width: 100%;
        display: flex;
    }

    ion-row {
        align-items: center;
        ion-col {
            --ion-grid-column-padding: 2px;
        }
    }

    &.ipad-safe-areas {
        // Keep iPad fab bar centered on the side due to 0-value safe areas
        &.r-start ion-row,
        &.r-end ion-row {
            margin-top: 40px;
        }
    }

    &.bottom {
        bottom: var(--ion-safe-area-bottom, 0);

        &.dp-edge {
            &.ios {
                transform: translateY(-25%);
            }
            &.md {
                transform: translateY(-30%);
            }
        }

        &.ipad-safe-areas {
            // extra distance from safe area due to fixed area layout
            bottom: 24px;
        }
    }

    &.r-start {
        width: auto;
        height: 100%;
        left: var(--ion-safe-area-left, 0);

        ion-col {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
        }

        &.no-gap {
            left: 0;
            bottom: 0;

            &.ipad-safe-areas {
                bottom: 0;
            }
        }

        // especially ios which is left aligned on rotation
        &.reverse {
            ion-col {
                flex-direction: column;
            }
        }
    }

    &.r-end {
        width: auto;
        height: 100%;
        right: var(--ion-safe-area-right, 0);

        ion-col {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
        }

        &.no-gap {
            right: 0;
            bottom: 0;

            &.ipad-safe-areas {
                bottom: 0;
            }
        }

        &.reverse {
            ion-col {
                flex-direction: column;
            }
        }
    }

    .dp-g-fab-btn[shape='round'] {
        --padding-top: 0.9rem;
        --padding-bottom: 0.9rem;
        --padding-end: 0.9rem;
        --padding-start: 0.9rem;
        border-radius: 50%;
        font-size: 1.3rem;

        --box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
        --transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), background-color 280ms cubic-bezier(0.4, 0, 0.2, 1),
            color 280ms cubic-bezier(0.4, 0, 0.2, 1), opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;

        &.ios {
            // iOS font scaling breaks round button layout
            font-size: 1rem;

            &::part(native) {
                width: 50px;
                height: 50px;
            }
        }

        &::part(native) {
            width: 56px;
            height: 56px;
        }
    }

    .dp-g-fab-btn[shape='round'][size='small'] {
        font-size: 1rem;

        &::part(native) {
            width: 40px;
            height: 40px;
        }
    }

    .dp-g-fab-btn[shape='round'][size='xlarge'] {
        font-size: 2.2rem;

        &::part(native) {
            width: 96px;
            height: 96px;
        }

        &.ios::part(native) {
            width: 112px;
            height: 112px;
        }
    }

    .dp-g-fab-btn {
        &.space-around {
            margin-left: 12px;
            margin-right: 12px;
        }
    }

    fa-layers {
        margin-left: -3px !important;
    }

    fa-icon {
        align-items: center;
        justify-content: center;
        display: flex;
    }
}

.dp-fab-list {
    margin-inline-start: 4px;
    .dp-fab-list-btn {
        margin-inline-start: 8px;
    }
}

/* Special os handling */
.Windows {
    .dp-g-fab {
        &.h-start {
            left: calc(12px + var(--ion-safe-area-right, 0px));
        }
        &.h-end {
            right: calc(12px + var(--ion-safe-area-right, 0px));
        }
    }
}

/**
 * CARD
 * ----------------------------------------------------------------------------
 */
ion-card {
    ion-card-header {
        color: var(--ion-text-color);
        padding-inline-start: 7px;
        padding-inline-end: 7px;
        padding-top: 7px;
        padding-bottom: 0;
        @media (max-width: 576px) {
            padding-bottom: 8px;
        }
    }
    ion-card-content {
        font-size: 1rem;
        color: var(--ion-text-color);
        &.card-content-md {
            font-size: 1rem;
            padding-inline-start: 7px;
            padding-inline-end: 7px;
            padding-top: 7px;
            padding-bottom: 7px;
            p {
                font-size: 1rem;
            }
        }
    }
    @media (max-width: 576px) {
        margin-inline-start: 0;
        margin-inline-end: 0;
        margin-top: 0;
    }
}
ion-card ion-list {
    background: var(--ion-card-background) !important;
}
.check-settings ion-card-content p,
.general-settings ion-card-content p,
.filter-settings ion-card-content p {
    //font-size: 14px !important;
}
.check-settings ion-card-content h2,
.general-settings ion-card-content h2,
.filter-settings ion-card-content h2 {
    //font-size: 16px !important;
}

.dp-info-card {
    ion-card-content {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-inline-start: 5px;
        padding-inline-end: 5px;
    }
}

/**
 * CHIPS
 * ----------------------------------------------------------------------------
 */
ion-chip {
    app-field-icon-view {
        --margin-left: 4px;
        --margin-right: 4px;

        fa-icon {
            //font-size: 12px;
        }
    }
    app-field-icon-view:first-child {
        --margin-left: 2px;
    }
    app-field-icon-view:last-child {
        --margin-right: 2px;
    }
}

/**
 * FORMS
 * ----------------------------------------------------------------------------
 */
// styling for default outline inputs
ion-input.input-fill-outline.sc-ion-input-md-h {
    --padding-start: 12px;
    --padding-end: 12px;
    font-size: 1rem;
    min-height: 50px; // reduced from default 56
}
.input-label-placement-stacked.sc-ion-input-md-h {
    min-height: 50px; // reduced from default 56
}
.input-label-placement-floating.sc-ion-input-md-h .label-text-wrapper.sc-ion-input-md {
    transform: translateY(80%) scale(1);
}
.label-floating.sc-ion-input-md-h .label-text-wrapper.sc-ion-input-md {
    transform: translateY(32%) scale(0.75);
}
// styling for default outline select fields
ion-select.select-fill-outline {
    min-height: 50px; // reduced from default 50
}

ion-input.disabled-input {
    pointer-events: none;
    opacity: 0.7;
}
.dp-input-outline {
    border: 1px solid var(--ion-color-step-300, #b3b3b3);
    border-radius: 4px;
    &:hover {
        border: 1px solid var(--ion-color-step-750, #404040);
    }
}
ion-textarea.sc-ion-textarea-md-h:not(.legacy-input, .no-min-height-auto) {
    min-height: auto;
}
.item-label-stacked.dp-ion-item-content-wrap,
.item-label-stacked .dp-ion-item-content-wrap,
.item-label-floating.dp-ion-item-content-wrap,
.item-label-floating .dp-ion-item-content-wrap {
    --padding-top: 8px;
    --padding-bottom: 8px;
    --padding-start: 0;
    background: transparent;
}

.dp-ion-item-content-wrap {
    // These styles are taken from the md styling! TODO: test with iOS
    --padding-top: 10px;
    --padding-end: 0;
    --padding-bottom: 10px;
    --padding-start: 8px;

    font-size: inherit;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 0 !important;
    background: var(--background);
    color: var(--color);
    font-family: var(--ion-font-family, inherit);
    z-index: 2;

    // justify-content: end;
    // text-align: right;

    .dp-ion-input {
        border-radius: var(--border-radius);
        padding-left: var(--padding-start);
        padding-right: var(--padding-end);
        padding-top: 0;
        padding-bottom: 0;
        font-family: inherit;
        font-size: inherit;
        font-style: inherit;
        font-weight: inherit;
        letter-spacing: inherit;
        text-decoration: inherit;
        text-indent: inherit;
        text-overflow: inherit;
        text-transform: inherit;
        text-align: inherit;
        white-space: inherit;
        color: inherit;
        display: inline-block;
        -ms-flex: 1;
        flex: 1;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        border: 0;
        outline: none;
        background: transparent;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    ngx-timepicker-field {
        .ngx-timepicker {
            border: none;

            .ngx-timepicker-control {
                height: 32px;

                .ngx-timepicker-control__input {
                    font-size: 1.05rem;
                }
            }

            .ngx-timepicker-control--active::after {
                bottom: -4px;
                background-color: var(--ion-color-primary);
            }

            .ngx-timepicker__toggle {
                padding-left: 2px;
                fa-icon {
                    svg {
                        height: 2rem;
                        color: var(--ion-color-primary);
                    }
                }
            }
        }
    }
}

.dp-time-picker-field ngx-timepicker-time-control .ngx-timepicker-control__input {
    background-color: inherit;
}

ion-datetime {
    --padding-top: 8px;
    --padding-bottom: 8px;
}

div.timepicker {
    overflow: hidden;
    border-radius: 4px !important;
    box-shadow:
        0 11px 15px -7px rgb(0 0 0 / 20%),
        0 24px 38px 3px rgb(0 0 0 / 14%),
        0 9px 46px 8px rgb(0 0 0 / 12%) !important;
    background: var(--background, #eef0f8);

    --dial-background-color: var(--ion-color-primary);
    --body-background-color: #eef0f8;
    --dial-editable-active-color: var(--ion-color-primary);
    --clock-face-background-color: #fff;
    --clock-hand-color: var(--ion-color-primary);

    .timepicker__actions {
        flex-direction: row-reverse;
        justify-content: end;
    }
}

mat-calendar {
    .mat-calendar-body-selected {
        background-color: var(--ion-color-primary);
    }

    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
        > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
    .cdk-keyboard-focused
        .mat-calendar-body-active
        > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
    .cdk-program-focused
        .mat-calendar-body-active
        > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        background-color: var(--ion-color-primary-tint);
        color: var(--ion-color-primary-contrast);
    }
}

.dp-fake-active-input {
    opacity: 1;
    color: var(--color);
    -webkit-text-fill-color: inherit;
    cursor: pointer;
    pointer-events: none;
}

/* placeholder elements only match correctly using following selectors WITHOUT classes */
input::placeholder,
input::-webkit-input-placeholder,
input::-moz-placeholder,
input:-ms-input-placeholder,
input:-moz-placeholder {
    color: gray;
    opacity: 0.4;
    -webkit-text-fill-color: inherit;
    -webkit-text-stroke: 1px;
}

// custom step buttons for number fields
input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
}
.number-field-step-btn {
    align-self: center;
    margin-left: 5px;
}

/**
 * CALENDAR
 * ----------------------------------------------------------------------------
 */
.dp-calendar-time-picker {
    .ngx-timepicker {
        justify-content: center;
    }
    .ngx-timepicker__toggle {
        margin-left: 10px;
    }
}
mwl-calendar-week-view-event {
    .cal-event-title {
        color: #000;
    }
}
mwl-calendar-month-cell.cal-weekend {
    background: #efefef;
}
.cal-month-view .cal-future,
.cal-month-view .cal-today {
    .cal-day-badge {
        background-color: var(--ion-color-primary);
    }
}

/**
 * TEXT EDITOR (FIELD TYPE)
 * ----------------------------------------------------------------------------
 */
.dp-text-editor-toolbar {
    ngx-editor-floating-menu {
        visibility: visible !important;
        opacity: 1 !important;
        position: relative;
        top: 0 !important;
        left: 0 !important;
    }
}
.NgxEditor__Popup {
    @media (max-width: 544px) {
        right: 0;
    }

    @media (max-width: 462px) {
        right: unset;
        left: 0;
    }

    form {
        color: var(--ion-text-color);
        .NgxEditor__Popup--FormGroup {
            .NgxEditor__HelpText {
                display: none;
            }
        }
        input {
            background: #fff;
            border: 1px solid var(--ion-border-color);
            border-radius: 2px;
        }
        button {
            background: var(--ion-color-primary);
            padding: 5px;
            border-radius: 3px;
            color: #fff;
        }
    }
}

// move popups in reference to container size
// broken at the moment due to floating constraints with overlapping fields
/*
.job-article-field.text-editor-field {
    container-type: normal;
    container-name: ngxeditorpopup;
}
@container ngxeditorpopup (max-width: 516px){
    .NgxEditor__Popup {
        right: 0;
    }
}
@container ngxeditorpopup (max-width: 516px){
    .NgxEditor__Popup {
        right: 0;
    }
}
*/

/*
@container (max-width: ){
    .card {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }

    .job-article-field-wrap:last-child .NgxEditor__Popup {

    }
}
*/

/**
 * LIST & ITEMS
 * ----------------------------------------------------------------------------
 */
ion-item.item-input {
    --border-radius: 5px 5px 0 0;
}
ion-virtual-scroll,
ion-list {
    ion-item {
        &.ion-item-active {
            --background: var(--ion-color-primary);
            ion-label,
            &:hover ion-label,
            span.marker-name {
                color: #fff !important;
            }
        }
        &.no-padding {
            --inner-padding-start: 0;
            --inner-padding-end: 0;
            --padding-start: 0;
        }
    }
    &.interactive-list {
        ion-item {
            cursor: pointer;
        }
    }
}

// no oultine for labels inside items
ion-item {
    ion-label {
        outline: none;
    }
}

cdk-virtual-scroll-viewport {
    height: 100%;
    width: 100%;

    .cdk-virtual-scroll-content-wrapper {
        width: 100%;
        max-width: 100%;
    }
}

/**
 * SPLIT VIEW
 * ----------------------------------------------------------------------------
 */
.dp-split-view {
    display: flex;
    flex-grow: 1;
    height: 100%;
    position: relative;
    .dp-split-view-menu {
        width: 22vw;
        flex-grow: 0;
        position: relative;
        z-index: 9;
        @media (max-width: 1000px) {
            width: 100vw;
        }
    }
    .dp-split-view-content {
        width: 78vw;
        flex-grow: 1;
        position: relative;
        z-index: 10;
        @media (max-width: 1000px) {
            width: 100vw;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #fff;
        }
    }
}

ion-split-pane.ios {
    ion-header {
        ion-toolbar {
            padding-top: 0;
        }
    }

    // ion-menu {
    //
    // }
}
ion-fab.ios {
    bottom: var(--ion-safe-area-bottom, 10px);
}
ion-fab.fab-vertical-top.ios {
    bottom: unset;
}

/**
 * MENUS
 * ----------------------------------------------------------------------------
 */

.dp-side-menu-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .dp-menu-close-btn {
        display: none;
        width: 100%;

        ion-item {
            --min-height: 56px;
        }
    }
    .dp-side-menu {
        --dp-side-menu-min-width: 280px;
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: var(--dp-side-menu-min-width, 280px);
    }

    .dp-side-menu-page-main-content {
        display: flex;
        flex-grow: 4;
    }
}

/* fullscreen when too small */
@media screen and (max-width: 560px) {
    .dp-side-menu-container {
        .dp-side-menu {
            .dp-menu-close-btn {
                display: block;
            }
            --dp-side-menu-min-width: 100%;
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: var(--dp-side-menu-min-width, 100%);
            flex-direction: column;

            app-project-plan-job-list {
                height: calc(100% - 56px);
            }

            &.dp-side-in-modal {
                app-project-plan-job-list {
                    height: 100%;
                }
            }
        }

        .dp-side-menu-page-main-content {
            flex-grow: 1;
        }
    }
}

/**
 * SEGMENTS
 * ----------------------------------------------------------------------------
 */
ion-segment {
    ion-segment-button {
        min-height: 38px;
        font-size: 1rem;
        letter-spacing: normal;
        text-transform: none;
    }
}
.segment-menu-icon {
    display: none;

    @media (max-width: 425px) {
        display: block;
    }
}

.segment-menu-text {
    display: none;

    @media (min-width: 426px) {
        display: block;
    }
}

/**
 * NOTICES
 * ----------------------------------------------------------------------------
 */
.dp-notice,
.notice {
    border: 3px solid var(--ion-color-primary);
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-flow: column;
    .notice-header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: var(--ion-color-primary);
        color: #fff;
        font-weight: 500;
        .notice-icon {
            font-size: 1.4rem;
            margin-right: 1rem;
        }
        .notice-title {
            //font-size: 18px;
        }
    }
    .notice-content {
        width: 750px;
        max-width: 100%;
        padding: 1rem;
    }
    &.notice-error {
        border-color: var(--ion-color-danger);
        .notice-header {
            background: var(--ion-color-danger);
        }
    }
    &.notice-success {
        border-color: var(--ion-color-success);
        .notice-header {
            background: var(--ion-color-success);
        }
    }
    &.notice-warning {
        border-color: var(--ion-color-warning);
        .notice-header {
            background: var(--ion-color-warning);
        }
    }
    &.small {
        .notice-header {
            .notice-icon {
                font-size: 1.4rem;
            }
            .notice-title {
                font-size: 1rem;
            }
        }
    }
}

body.swal2-toast-shown .swal2-container.swal2-top {
    top: calc(var(--ion-safe-area-top, 0)) !important;
}

/**
 * TOOLTIPS (TIPPY)
 * ----------------------------------------------------------------------------
 */
body .tippy-box {
    //font-size: 16px;
}
body .tippy-box[data-theme='light'] {
    background-color: #fff;
    color: #000;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.4);
    .tippy-svg-arrow {
        fill: #fff;
    }
}

/**
 * SELECT DROPDOWN (NG-SELECT)
 * ----------------------------------------------------------------------------
 */
.ng-select .ng-select-container,
ng-dropdown-panel {
    border-color: var(--ion-border-color) !important;
}
//body .ng-select .ng-select-container,
//body .ng-select.ng-select-single .ng-select-container {
//    min-height: 30px;
//    height: 30px;
//}
ng-select {
    flex: 1;
}
ion-item {
    ng-select,
    .ng-select {
        width: 100%;
        //margin-top: 4px;

        &.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
        .ng-select-container {
            background: transparent;
            border: none !important;
            box-shadow: none;
        }
    }
}

/**
 * ION INPUT FIELDS
*/
ion-item ion-select::part(label) {
    max-width: 70%;
}
.dp-label {
    p {
        color: #696969;
    }
}

/**
 * COLOR PICKER (NGX-COLOR)
 * ----------------------------------------------------------------------------
 */
color-editable-input input {
    background-color: transparent;
}

/**
 * ACCOUNTING FORMULA EDITOR (QUILL)
 * ----------------------------------------------------------------------------
 */
.quill-formula-editor {
    .ql-editor {
        div {
            display: inline-block;
        }
    }
}
.formular-editor {
    min-width: 100%;
}

/**
 * ACCOUNTING TABLE
 * ----------------------------------------------------------------------------
 */

/**
 * TIME TRACKING TABLE
 * @deprecated cause we use primeng tables now
 * ----------------------------------------------------------------------------
 */
.time-tracking-table-wrap {
    .tt-edit-cell {
        padding: 0.3rem 0.9rem 0 !important;
    }
}

/**
 * NEW Camera
 */
.dp-camera-modal {
    --min-height: 100%;
    --min-width: 100%;
}

/**
 * Barcode / QR Scanner tricks
 * ----------------------------------------------------------------------------
 **/

.camera-preview-visible {
    background: transparent;
    --background: transparent;

    app-header {
        display: none;
    }
    #main {
        background: transparent;
        --background: transparent;

        & > ion-content {
            --background: transparent;
            display: none;
        }
    }
    ion-modal {
        --backdrop-opacity: 0;
        --background: transparent;

        ion-header {
            display: none;
        }
        ion-content {
            --background: transparent;
            visibility: hidden;
        }

        app-dp-camera {
            ion-content {
                visibility: visible;
            }
        }
    }

    // hide the toolbars of the text editors
    .dp-text-editor-toolbar ngx-editor-floating-menu {
        display: none;
    }
}

.scanner-visible {
    background: transparent;
    --background: transparent;

    #main {
        background: transparent;
        --background: transparent;
        & > ion-content {
            --background: transparent;
        }
    }

    &.scanner-in-modal {
        #main {
            & > ion-content {
                display: none;
            }
        }

        ion-modal {
            --background: transparent;
            .dp-modal-header {
                visibility: hidden;
            }
            ion-content {
                --background: transparent;

                .component-wrap {
                    visibility: hidden;
                }

                /* special case for building plan starting point as workflow */
                app-building-plan-details {
                    display: none;
                }
            }

            ion-backdrop {
                display: none;
            }
        }
    }

    &.scanner-in-dialog {
        #main {
            visibility: hidden;
        }
    }

    &.scanner-in-dialog.scanner-mobile {
        #main {
            visibility: hidden;
            display: none;
        }
        ion-modal {
            display: none;
        }
    }
}

.dialog-scanner {
    text-align: left !important;
}

.no-brackground {
    background: transparent !important;
}

/**
 * Modals
 * ----------------------------------------------------------------------------
 **/
.dp-modal-header {
    background: linear-gradient(
        90deg,
        rgba(0, 30, 51, 1) 0%,
        rgba(32, 146, 201, 1) 20%,
        rgba(32, 146, 201, 1) 80%,
        rgba(0, 30, 51, 1) 100%
    );
    ion-toolbar {
        --background: #313539;
        --color: white;

        &.top-toolbar {
            --background: transparent;
        }

        ion-button {
            --color: white;
        }

        ion-segment.ios {
            padding: 2px;
            ion-segment-button {
                --color-checked: #000;
            }
        }

        ion-segment.md {
            padding: 8px;

            ion-segment-button {
                --indicator-color: transparent;
                border-right: 1px solid rgba(255, 255, 255, 0.4);

                &:last-child {
                    border-right: none;
                }
                &::part(indicator) {
                    display: none;
                }
            }
        }
    }
}
ion-modal {
    &.modal-fullscreen {
        --width: 100vw;
        --height: 100vh;
    }
    &.modal-fullheight {
        --height: 100vh;
    }
    &.modal-height-auto {
        &.bottom {
            align-items: flex-end;
        }

        --height: auto;

        .ion-page {
            position: relative;
            display: block;
            contain: content;

            .inner-content {
                // auto height modals must have its content inside a div with this class
                max-height: 90vh;
                overflow: auto;
            }
        }
    }
    &.modal-xl {
        --width: 98vw;
        --height: 97vh;
        &.modal-height-auto {
            --height: auto;
        }
    }
    &.modal-l {
        @media (max-width: 1020px) {
            --width: 98vw;
            --height: 97vh;
            &.modal-height-auto {
                --height: auto;
            }
        }
        --width: 80vw;
        --height: 97vh;
        &.modal-height-auto {
            --height: auto;
        }
    }
    &.modal-m {
        @media (max-width: 1020px) {
            --width: 98vw;
            --height: 97vh;
            &.modal-height-auto {
                --height: auto;
            }
        }
        --width: 60vw;
        --height: 80vh;
        &.modal-height-auto {
            --height: auto;
        }
    }
    &.modal-s {
        @media (max-width: 1020px) {
            --width: 98vw;
            --height: 97vh;
            &.modal-height-auto {
                --height: auto;
            }
        }
        --width: 30vw;
        --height: 50vh;
        &.modal-height-auto {
            --height: auto;
        }
    }
    &.mobile-fullscreen {
        @media (max-width: 728px) {
            --width: 100vw;
            --height: 100vh;
        }
    }
    &.stack-modal {
        // used to apply box-shadow and backdrop on nested modals (by default nested modals do not have box-shadow and backdrop is invisible
        --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
        --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
    }
}

/**
 * Popovers
 * ----------------------------------------------------------------------------
 **/
ion-popover,
ion-alert,
ion-picker {
    z-index: 999999 !important;

    &.select-popover {
        --width: auto;
    }
}

.dp-service-group-list {
    --width: auto;
}

/**
 * Leaflet styles
 */

// =============== Leaflet Visual Click

/*
* Heavily based on the CSS for https://github.com/mapshakers/leaflet-icon-pulse
*
* Modified to get rid of the opaque circle, and tweaked the pulse to be subtler.
*/

#dp-leaflet-wrap .leaflet-grab {
    cursor: auto;
}

.leaflet-dragging #dp-leaflet-wrap .leaflet-grab {
    cursor: move;
}

.leaflet-visualclick-icon {
    border-radius: 100%;
    pointer-events: none;
}

.leaflet-visualclick-icon:after {
    content: '';
    border-radius: 100%;
    height: 60px;
    width: 60px;
    position: absolute;
    margin-left: -30px;
    margin-top: -30px;
    pointer-events: none;

    box-shadow:
        inset 0 0 25px -1px #e1e3e4,
        0 0 10px -1px #c5c5c5;

    animation: visualclick-pulsate 0.7s ease-out;
    animation-iteration-count: 1;
    animation-delay: 0s;
    opacity: 0;
}

.leaflet-visualclick-icon-touch:after {
    content: '';
    border-radius: 100%;
    height: 140px;
    width: 140px;
    position: absolute;
    margin-left: -70px;
    margin-top: -70px;
    pointer-events: none;

    box-shadow:
        inset 0 0 25px -1px #e1e3e4,
        0 0 10px -1px #c5c5c5;

    animation: visualclick-pulsate-touch 0.7s ease-out;
    animation-iteration-count: 1;
    animation-delay: 0s;
    opacity: 0;
}

@keyframes visualclick-pulsate {
    0% {
        transform: scale(0.5);
        opacity: 1;
    }
    100% {
        transform: scale(1.3);
        opacity: 0;
    }
}

@keyframes visualclick-pulsate-touch {
    from {
        transform: scale(1);
        opacity: 0.8;
    }
    to {
        transform: scale(0.2);
        opacity: 0;
    }
}

.leaflet-pane.leaflet-ie10-visual-click-pane-pane {
    z-index: 300;
}

// =========== Leaflet Marker Cluster

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
    -webkit-transition:
        -webkit-transform 0.3s ease-out,
        opacity 0.3s ease-in;
    -moz-transition:
        -moz-transform 0.3s ease-out,
        opacity 0.3s ease-in;
    -o-transition:
        -o-transform 0.3s ease-out,
        opacity 0.3s ease-in;
    transition:
        transform 0.3s ease-out,
        opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
    /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
    -webkit-transition:
        -webkit-stroke-dashoffset 0.3s ease-out,
        -webkit-stroke-opacity 0.3s ease-in;
    -moz-transition:
        -moz-stroke-dashoffset 0.3s ease-out,
        -moz-stroke-opacity 0.3s ease-in;
    -o-transition:
        -o-stroke-dashoffset 0.3s ease-out,
        -o-stroke-opacity 0.3s ease-in;
    transition:
        stroke-dashoffset 0.3s ease-out,
        stroke-opacity 0.3s ease-in;
}

.marker-cluster-small {
    background-color: rgba(181, 226, 140, 0.6);
}
.marker-cluster-small div {
    background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
    background-color: rgba(241, 211, 87, 0.6);
}
.marker-cluster-medium div {
    background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
    background-color: rgba(253, 156, 115, 0.6);
}
.marker-cluster-large div {
    background-color: rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
    background-color: rgb(181, 226, 140);
}
.leaflet-oldie .marker-cluster-small div {
    background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
    background-color: rgb(241, 211, 87);
}
.leaflet-oldie .marker-cluster-medium div {
    background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
    background-color: rgb(253, 156, 115);
}
.leaflet-oldie .marker-cluster-large div {
    background-color: rgb(241, 128, 23);
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
}
.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;

    text-align: center;
    border-radius: 15px;
    font:
        12px 'Helvetica Neue',
        Arial,
        Helvetica,
        sans-serif;
}
.marker-cluster span {
    line-height: 30px;
}

// Custom Leaflet Styles

.leaflet-marker-icon {
    &.leaflet-div-icon,
    &.leaflet-dp-icon {
        background: transparent;
        border: none;

        .fas,
        .fad,
        .fal {
            font-size: 36px;
            display: block;
            margin: auto;
            text-align: center;

            &.custom-icons {
                font-size: 48px;
            }
        }
    }
}

.building-plan-wrap {
    .leaflet-map-pane {
        canvas {
            cursor: pointer;
        }
    }
}

.leaflet-marker-icon {
    .job-marker-number {
        position: absolute;
        top: -22px;
        min-width: 100%;
        text-align: center;
        color: white;
        text-shadow: 0 0 3px #000;
        font-size: 1.35em;
        font-weight: bold;
        left: 50%;
        transform: translateX(-50%);
        max-width: 200px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .job-marker-custom-icons {
        position: absolute;
        top: 6px;
        left: 12px;
        display: flex;
        align-items: center;

        .job-marker-custom-icon {
            &.dp-svg {
                width: 24px;
                height: auto;
                color: var(--dp-icon-color, inherit);
                fill: var(--dp-icon-color, inherit);
            }
        }

        i.job-marker-custom-icon {
            font-size: 22px;
        }

        :nth-child(2) {
            margin-left: 7px;
            padding: 3px;
            background: white;
            border-radius: 50%;
        }
    }
}

.dp-map-info {
    .control-content {
        background: rgba(255, 255, 255, 0.9);
        padding: 8px;
        margin-right: 0;
        color: #222;
        font-size: 16px;

        max-width: 300px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .content-padding {
        padding: 0 8px;
    }
}
.leaflet-right {
    .dp-map-info {
        &.leaflet-control {
            margin-right: 0;
        }
    }
}

.leaflet-left {
    .dp-map-info {
        &.leaflet-control {
            margin-left: 0;

            .color-warning {
                color: var(--ion-color-warning);
            }
        }
    }
}

a.map-marker-delete-btn {
    color: #f4516c !important;
}
a.map-marker-delete-btn:hover {
    color: #fff !important;
}
a.map-marker-view-job-btn {
    color: #333 !important;
}
a.map-marker-view-job-btn:hover {
    color: #fff !important;
}
.job-popup-name {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
    font-size: 1.2em;
    font-weight: bold;
}
.job-popup-qr {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-style: italic;
    padding-bottom: 5px;
}
.map-marker {
    border: none;
}
.map-marker .marker-job-number {
    display: block;
    padding: 0;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    margin-bottom: -9px;
    text-shadow: 0 0 5px #000;
}
.map-marker img.marker-img {
    position: relative;
    z-index: 4;
    max-width: 100% !important;
}

.leaflet-popup {
    .leaflet-popup-content-wrapper {
        margin: 0;
        background: transparent;
        box-shadow: none;

        .leaflet-popup-content {
            margin: 0;
            // width: auto !important;
            background: transparent;
            box-shadow: none;
        }
    }

    .leaflet-popup-tip-container,
    .leaflet-popup-close-button {
        display: none;
    }
}

/* card styles for our custom marker popups */
/* TODO: Delete this when custom mappopupcomponent is used */
.marker-popup-card {
    max-width: 300px;
    width: auto;

    ion-card-header {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-inline-end: 0;
        padding-inline-start: 7px;

        &.ios {
            padding-inline-end: 7px;
        }

        ion-toolbar {
            --min-height: 20px;
        }
    }
    ion-card-content {
        padding-top: 0 !important;
    }

    .marker-popup-toolbar {
        padding-left: 8px;
        .marker-popup-title {
            font-size: 17px;
        }
    }

    .marker-popup-close-btn {
        // padding: 8px;
        i {
            font-size: 16px;
        }
    }

    .marker-job-code {
        border: none;
        padding: 8px 4px;
        padding-inline-start: 12px;
        padding-inline-end: 12px;
        margin: 0;

        i {
            margin-right: 8px;
        }
    }

    .marker-job-sg {
        --min-height: 32px;
        margin: 4px;
        display: block;
    }
}

// .marker-popup-content {
// 	max-width: 260px;
// 	text-align: center;
// }
.marker-popup-content .marker-not-saved-notice {
    display: inline-block;
    font-style: italic;
    font-size: 0.9em;
    padding-top: 5px;
}

/**
 * ARTICLE ICONS
 * ----------------------------------------------------------------------------
 */
.article-icon-definition {
    width: auto !important;
    div.ng-option .ng-option-label {
        display: flex;
        align-items: center;
    }
    /*.article-icon-selection-img {
		width: 30px;
		margin-right: 5px;
	}*/
    fa-icon {
        margin-right: 5px;
    }
}

.select-field-selected-value {
    display: flex;
    align-items: center;
    .article-icon-selection-img {
        width: 30px;
        margin-right: 5px;
    }
    fa-icon {
        margin-right: 5px;
    }
}

/**
 * PAGE BLOCKING LOADER
 * ----------------------------------------------------------------------------
 */
.dp-page-blocking-loader {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 0;
    height: 100vh;
    transition: width 0s 1s ease;
}
.dp-page-blocking-loader .loader__icon {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    opacity: 0;
    transition: opacity 0.5s ease;
}
.dp-page-blocking-loader .loader__icon svg {
    transform-origin: 0 0;
}
.dp-page-blocking-loader .loader__tile {
    position: absolute;
    left: 0;
    width: 0;
    height: 20%;
    background-color: var(--ion-color-primary);
    transition: width 0.5s ease;
}
.dp-page-blocking-loader .loader__tile:nth-child(0) {
    top: calc(-1 * 20%);
    transition-delay: -0.1s;
}
.dp-page-blocking-loader .loader__tile:nth-child(1) {
    top: calc(0 * 20%);
    transition-delay: 0s;
}
.dp-page-blocking-loader .loader__tile:nth-child(2) {
    top: calc(1 * 20%);
    transition-delay: 0.1s;
}
.dp-page-blocking-loader .loader__tile:nth-child(3) {
    top: calc(2 * 20%);
    transition-delay: 0.2s;
}
.dp-page-blocking-loader .loader__tile:nth-child(4) {
    top: calc(3 * 20%);
    transition-delay: 0.3s;
}
.dp-page-blocking-loader .loader__tile:nth-child(5) {
    top: calc(4 * 20%);
    transition-delay: 0.4s;
}
.app-tab-locked {
    #main {
        --app-header-h: 0px !important;
    }

    #startup-loader-wrap,
    .dp-page-blocking-loader {
        display: none !important;
    }
}

.dp-loader-active {
    z-index: 999999;
    width: 100%;
    transition-delay: 0s;
}
.dp-loader-active .loader__icon {
    opacity: 1;
    transition: opacity 0.5s 1s ease;
}
.dp-loader-active .loader__tile {
    width: 100%;
}
.dp-loader-active .loader__tile:nth-child(0) {
    transition-delay: -0.1s;
}
.dp-loader-active .loader__tile:nth-child(1) {
    transition-delay: 0s;
}
.dp-loader-active .loader__tile:nth-child(2) {
    transition-delay: 0.1s;
}
.dp-loader-active .loader__tile:nth-child(3) {
    transition-delay: 0.2s;
}
.dp-loader-active .loader__tile:nth-child(4) {
    transition-delay: 0.3s;
}
.dp-loader-active .loader__tile:nth-child(5) {
    transition-delay: 0.4s;
}

/**
 * MICROSOFT EDGE PASSWORT "EYE" DISABLE
 * ----------------------------------------------------------------------------
 */
.edge-disable-eye,
.edge-disable-eye input {
    &::-ms-reveal,
    &::-ms-clear {
        display: none !important;
    }
}

/**
 * UPDATES PAGE
 * ----------------------------------------------------------------------------
 */
.patchnotes {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 10px !important;
    }
}

/**
 * REPORT SIGNATURES
 */
.creating-report-signature-action {
    .signature-start-wrap {
        text-align: right !important;
    }
}

/**
 * FIELD DISPLAY DYNAMIC ON JOB PAGE
 */
@media (min-width: 768px) {
    .job-article-fields.field-display-dynamic {
        display: flex;
        flex-wrap: wrap;
        .job-article-field-wrap {
            flex-grow: 1;
            padding: 2px;
        }
    }
    .field-display-dynamic .job-field-group-sub-fields {
        display: flex;
        flex-wrap: wrap;
        .job-sub-field-wrap {
            flex-grow: 1;
        }
    }
}

/* Pintura Editor styles */
.PinturaButton.PinturaButtonIconOnly.PinturaInputFormButtonConfirm,
.PinturaButton.PinturaButtonExport {
    --color-primary: var(--ion-color-success);
    --color-primary-text: var(--ion-color-success-contrast, white);
}

.PinturaRoot .PinturaButton.PinturaButtonExport:hover {
    --color-primary-dark: var(--ion-color-success-tint);
}

.PinturaRoot > .PinturaNav .PinturaButton .PinturaButtonInner,
.PinturaRoot > .PinturaNav .PinturaButtonIconOnly .PinturaButtonInner,
.PinturaRoot > .PinturaNav .PinturaDropdownIconOnly .PinturaButtonInner {
    height: 2.4em;
    width: 2.4em;
}
.PinturaRoot {
    padding-bottom: var(--ion-safe-area-bottom);
}

// push containing box of tools further up to not overlap with cancel and reset buttons
.PinturaRootWrapper .PinturaRoot[data-env~='landscape'][data-env~='has-limited-space'] > .PinturaNavMain,
.PinturaRootWrapper .PinturaRoot[data-env~='landscape'][data-env~='has-limited-space'] > .PinturaNavMain {
    bottom: calc(5em + var(--editor-calculated-inset-bottom));
}

.ios {
    .PinturaNav.PinturaNavTools {
        padding-bottom: 0 !important;
    }
}

#camera-preview-container {
    .PinturaRoot {
        padding-top: var(--ion-safe-area-top);
    }
}

/**
 * CRISP CHAT STYLES
 * CSS classes are autogenerated so we need to rely on the DOM
 * [data-full-view=true] indicates mobile fullscreen where the notch can be aproblem
 * everything else is big enough to fit the window
 */
// Move header by safe area
#crisp-chatbox[data-full-view='true'] > div > div > div > div {
    padding-top: var(--ion-safe-area-top) !important;
}

// move close button as well
#crisp-chatbox[data-full-view='true'] > div > div > div > div > span > span {
    top: calc(var(--ion-safe-area-top) + 10px) !important;
}

// and move the bottom part up from the home buttons etc
#crisp-chatbox[data-full-view='true'] > div > div > div:nth-child(2) > div {
    //margin-bottom: calc(var(--ion-safe-area-bottom) + 44px) !important;
    margin-bottom: var(--ion-safe-area-bottom, 44px) !important;
}

/* prime Overrides with DokuPit styles */
@layer dokupit {
    // datatable style
    table {
        border-collapse: unset;

        td,
        th {
            padding: 1rem 1rem;
        }
    }

    .accounting-datatable table,
    .time-tracking-table table {
        table-layout: fixed;
    }

    .accounting-datatable table th,
    .accounting-datatable table td,
    .time-tracking-table table th,
    .time-tracking-table table td {
        width: 200px;
    }

    .accounting-datatable p-scroller,
    .time-tracking-table p-scroller {
        height: 100%;
    }
}

mat-button-toggle-group {
    mat-button-toggle {
        --mat-standard-button-toggle-height: 1;
        button {
            span {
                padding: 5px 12px;
            }
        }
    }
}

.checkbox-item,
.input-design {
    border: 1px solid var(--ion-color-step-300, #b3b3b3);
    border-radius: 4px;

    &:hover {
        border: 1px solid var(--ion-color-step-750, #404040);
    }

    .item-desc {
        font-style: italic;
        font-size: 0.8rem;
        line-height: normal;
    }
}

ion-button.info-hover-icon {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --background: transparent;
    --background-focused-opacity: 0;
    --background-hover-opacity: 0;
    &.top-right {
        position: absolute;
        top: 0;
        right: 0;
    }
    &.info-hover-icon-inline {
        margin: 0 0.5rem;
        text-align: center;
        width: auto;
        height: auto;
    }
}

.dp-floating-field {
    position: relative;
    //height: 100%;
    //min-height: 56px;
    cursor: pointer;

    .field-outline-container {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        pointer-events: none;
        --border-color: var(--ion-color-step-300, #b3b3b3);
        --border-radius: 6px;

        &.no-label {
            .field-outline-notch {
                .notch-spacer {
                    display: none;
                }
            }
        }

        .field-outline-start {
            --padding-start: 16px;
            width: calc(var(--padding-start) - 4px);
            border-top: 1px solid var(--border-color);
            border-bottom: 1px solid var(--border-color);
            border-inline-start: 1px solid var(--border-color);
            border-radius: var(--border-radius) 0 0 var(--border-radius);
        }

        .field-outline-notch {
            border-bottom: 1px solid var(--border-color);

            .notch-spacer {
                font-size: calc(1em * 0.75);
                transform: translateY(-50%);
                padding-left: 4px;
                padding-right: 4px;
            }
        }

        .field-outline-end {
            flex-grow: 1;
            border-top: 1px solid var(--border-color);
            border-bottom: 1px solid var(--border-color);
            border-inline-end: 1px solid var(--border-color);
            border-radius: 0 var(--border-radius) var(--border-radius) 0;
        }
    }

    &:hover {
        .field-outline-container {
            --border-color: var(--ion-color-step-750, #404040);
        }
    }

    .dp-combo-picker-wrap {
        --border-radius: 6px;
        height: 100%;
        min-height: 56px;
        border: 1px solid transparent;
        border-radius: var(--border-radius);

        &.bg-light {
            background: var(--ion-color-light) !important;
        }

        input {
            color: black;
            text-align: left;
            --padding-start: 16px;
        }
    }

    // styling for ng-select
    ng-select {
        //height: 100%;
        //height: 56px;
        z-index: 1;

        &.ng-select-focused .ng-select-container {
            box-shadow: none !important;
        }

        &.ng-select-multiple {
            .ng-value-container {
                padding: 0 !important;
            }
            .ng-placeholder {
                top: 50% !important;
                transform: translate(0, -50%);
                padding: 0 !important;
            }
            .ng-select-container .ng-input {
                padding: 0 !important;
            }
        }

        .ng-select-container {
            min-height: 56px;
            background: var(--ion-color-light) !important;
            border: none !important;

            .ng-placeholder {
                color: black !important;
            }

            .ng-value-container,
            .ng-input {
                padding-left: 16px !important;
            }

            .ng-arrow-wrapper {
                padding-right: 16px;

                .ng-arrow {
                    border-color: var(--ion-color-step-500, gray) transparent transparent !important;
                }
            }

            .ng-input {
                top: 0 !important;
                height: 100%;

                input {
                    cursor: pointer !important;
                    height: 100%;
                }
            }
        }
    }

    ngx-timepicker-field {
        .ngx-timepicker {
            background: var(--ion-color-light);
            border: 1px solid transparent;
            border-radius: 8px;
            height: 56px;
        }

        .ngx-timepicker-control {
            border: 0;

            input {
                background: transparent;
            }

            &.ngx-timepicker-control--active:after {
                height: 0;
            }
        }

        ngx-material-timepicker-toggle {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 16px;
        }
    }
}
