/**
 * MARGIN UTILITY
 * ----------------------------------------------------------------------------
 */
.dp-m-s {
    margin: 0.5rem;
}
.dp-mt-s {
    margin-top: 0.5rem;
}
.dp-mr-s {
    margin-right: 0.5rem;
}
.dp-mb-s {
    margin-bottom: 0.5rem;
}
.dp-ml-s {
    margin-left: 0.5rem;
}
.dp-m-m {
    margin: 1.2rem;
}
.dp-mt-m {
    margin-top: 1.2rem;
}
.dp-mr-m {
    margin-right: 1.2rem;
}
.dp-mb-m {
    margin-bottom: 1.2rem;
}
.dp-ml-m {
    margin-left: 1.2rem;
}
.dp-m-l {
    margin: 2rem;
}
.dp-mt-l {
    margin-top: 2rem;
}
.dp-mr-l {
    margin-right: 2rem;
}
.dp-mb-l {
    margin-bottom: 2rem;
}
.dp-ml-l {
    margin-left: 2rem;
}
.dp-m-0 {
    margin: 0;
}
.dp-mt-0 {
    margin-top: 0;
}
.dp-mr-0 {
    margin-right: 0;
}
.dp-mb-0 {
    margin-bottom: 0;
}
.dp-ml-0 {
    margin-left: 0;
}

/**
 * PADDING UTILITY
 * ----------------------------------------------------------------------------
 */
.dp-p-s {
    padding: 0.5rem;
}
.dp-pt-s {
    padding-top: 0.5rem;
}
.dp-pr-s {
    padding-right: 0.5rem;
    --padding-end: 0.5rem;
}
.dp-pb-s {
    padding-bottom: 0.5rem;
}
.dp-pl-s {
    padding-left: 0.5rem;
    --padding-start: 0.5rem;
}
.dp-pt-m {
    padding-top: 1rem;
}
.dp-p-m {
    padding: 1rem;
}
.dp-pr-m {
    padding-right: 1rem;
}
.dp-pb-m {
    padding-bottom: 1rem;
}
.dp-pl-m {
    padding-left: 1rem;
}
.dp-pt-0 {
    padding-top: 0 !important;
}
.dp-pr-0 {
    padding-right: 0 !important;
    --padding-end: 0;
}
.dp-pb-0 {
    padding-bottom: 0 !important;
}
.dp-pl-0 {
    padding-left: 0 !important;
    --padding-left: 0;
}
.no-padding,
.dp-p-0 {
    padding: 0 !important;
    --padding-start: 0 !important;
    --padding-end: 0 !important;
    --padding-top: 0 !important;
    --padding-bottom: 0 !important;
}
.no-padding-start {
    --padding-start: 0 !important;
}
.no-padding-end {
    --padding-end: 0 !important;
}

/**
 * COLUMN SPACING
 * ----------------------------------------------------------------------------
 */

// should not work on mobile screens due to screen border
@media screen and (min-width: 768px) {
    ion-col {
        &.no-spacing {
            --ion-grid-column-padding: 0;
        }
    }
}

/**
 * DISPLAY
 * ----------------------------------------------------------------------------
 */
.d-flex {
    display: flex;
}

.hidden {
    visibility: hidden !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

/**
 * COLOR
 * ----------------------------------------------------------------------------
 */
.color-primary {
    color: var(--ion-color-primary);
}
.color-danger {
    color: var(--ion-color-danger);
}
.color-warning {
    color: var(--ion-color-warning);
}
.color-success {
    color: var(--ion-color-success);
}
.color-white {
    color: #fff;
}
.color-black {
    color: #000;
}

/**
 * BACKGROUND COLOR
 * ----------------------------------------------------------------------------
 */
.bg-white {
    --ion-item-background: #fff;
}
.bg-default {
    --background: var(--ion-background-color);
}
.bg-primary {
    --background: var(--ion-color-primary);
}
.bg-secondary {
    --background: var(--ion-color-secondary);
}
.bg-tertiary {
    --background: var(--ion-color-tertiary);
}
.bg-light {
    --background: var(--ion-color-light);
}
.bg-transparent {
    --background: transparent;
    background: transparent;
}
.bg-item-default {
    --background: var(--ion-item-background);
}

/**
 * FONT SIZE
 * ----------------------------------------------------------------------------
 */
.fs-normal {
    font-size: 1em;
}
.fs-bigger {
    font-size: 1.3em;
}
.fs-bigger-2 {
    font-size: 1.5em;
}

/**
 * WHITE SPACE
 * ----------------------------------------------------------------------------
 */
.ws-normal {
    white-space: normal !important;
}

/**
 * BORDER
 * ----------------------------------------------------------------------------
 */
.dp-border-right {
    border-right: 1px solid var(--ion-border-color);
    &.dp-border-thick {
        border-right-width: 2px;
    }
}
.dp-border-bottom {
    border-bottom: 1px solid var(--ion-border-color);
    &.dp-border-thick {
        border-bottom-width: 2px;
    }
}
.dp-border-left {
    border-left: 1px solid var(--ion-border-color);
    &.dp-border-thick {
        border-left-width: 2px;
    }
}
.dp-border-top {
    border-top: 1px solid var(--ion-border-color);
    &.dp-border-thick {
        border-top-width: 2px;
    }
}
.dp-border-dark {
    border-color: var(--ion-border-color-dark);
}

/**
 * VARIOUS
 * ----------------------------------------------------------------------------
 */
ion-label.label-always-floating {
    transform: translateY(50%) scale(0.75) !important;
}

.list-margin-top {
    margin-top: 16px;
    margin-bottom: calc(16px + var(--ion-safe-area-bottom));
}
.list-margin-bottom {
    margin-bottom: calc(72px + var(--ion-safe-area-bottom));
}

.list-padding-top {
    padding-top: 16px;
    padding-bottom: var(--ion-safe-area-bottom);
}
.list-padding-bottom {
    padding-bottom: calc(72px + var(--ion-safe-area-bottom));
}

// fix leaflet intersecting with drop shadow or fabs
.dp-above-leaflet {
    z-index: 1001;
}

// 100% content height subsctracted with current header height
// e.g. for lists with ion-header above
.dp-adaptive-content-wrap {
    height: calc(100% - var(--header-height) - var(--content-inset, 0px));
}

/**
 * ROTATE ANIMATION
 * ----------------------------------------------------------------------------
 */
/* Safari and Chrome */
@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
